/* CommentSect.css */

.list-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

@media (min-width: 768px) {
  .list-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.list-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 8px 0;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.list-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .list-item {
    width: calc(50% - 16px);
    margin: 8px;
  }
}

@media (min-width: 1024px) {
  .list-item {
    width: calc(33.333% - 16px);
  }
}

.list-title {
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .list-title {
    font-size: 20px;
  }
}

.list-description {
  margin: 0;
  font-size: 14px;
}

@media (min-width: 768px) {
  .list-description {
    font-size: 16px;
  }
}

.component-title {
  text-align: center;
  margin: 16px 0;
  font-size: 24px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .component-title {
    font-size: 28px;
  }
}
